<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <div class="vx-row">
      <div class="vx-col w-full">
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="CreditCardIcon"
          icon-right
          :statistic="investMine"
          statisticTitle="Your Invesment"
        />
      </div>
    </div>

    <vs-table ref="table" :data="invests">
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <div
          class="flex flex-wrap-reverse items-center data-list-btn-container"
        >
          <!-- Re Invesment -->
          <ReInvesment @startInvestInterval="startInvestInterval" />

          <!-- Burn -->
          <Burn />
        </div>
      </div>

      <template slot="thead">
        <vs-th sort-key="name">app</vs-th>
        <vs-th sort-key="ecs_invest">ecg amount</vs-th>
        <vs-th sort-key="invest">usdt amount</vs-th>
        <vs-th sort-key="ecs_invest">ecg burned</vs-th>
        <vs-th sort-key="invest">usdt burned</vs-th>
        <vs-th sort-key="status">status</vs-th>
        <vs-th sort-key="created_at">time</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr
            :data="tr"
            :key="indextr"
            v-for="(tr, indextr) in data"
            @click="goToChat(tr.id)"
          >
            <vs-td>
              <p class="font-medium">{{ tr.name }}</p>
            </vs-td>

            <vs-td>
              <p class="font-medium">{{ tr.invest_ecs }}</p>
            </vs-td>

            <vs-td>
              <p class="font-medium">{{ tr.invest_usdt }}</p>
            </vs-td>

            <vs-td>
              <p class="font-medium text-danger">{{ tr.burned_ecs }}</p>
            </vs-td>

            <vs-td>
              <p class="font-medium text-danger">{{ tr.burned_usdt }}</p>
            </vs-td>

            <vs-td>
              <p class="font-medium" :class="status(tr.status)">
                {{ tr.status }}
              </p>
            </vs-td>

            <vs-td>
              <p class="font-medium">
                {{ tr.created_at | moment("MMMM Do YYYY") }}
              </p>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>

    <!-- <vs-pagination :total="10" v-model="page"></vs-pagination> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ReInvesment from "./ReInvesment.vue";
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";
import Burn from "./Burn.vue";

export default {
  components: {
    ReInvesment,
    StatisticsCardLine,
    Burn
  },
  data() {
    return {
      page: 4,
      selected: [],
      investInterval: null
    };
  },
  computed: {
    ...mapGetters({
      invests: "modulePayment/invests",
      investMine: "modulePayment/investMine"
    })
  },
  methods: {
    // Vuex Map Actions
    ...mapActions({
      getBaseInfo: "contract/getBaseInfo",
      getInvests: "modulePayment/getInvests",
      getDashboardData: "auth/getDashboardData"
    }),
    //
    reInvesment() {
      this.toggleReInvesmentSidebar(true);
    },
    toggleReInvesmentSidebar(val = false) {
      this.ReInvesment = val;
    },

    burn() {
      this.toggleBurnSidebar(true);
    },

    toggleBurnSidebar(val = false) {
      this.Burn = val;
    },

    // Invesmant Status Color
    status(status) {
      if (status === "calculated" || status === "paid") return "text-success";
      if (
        status === "calculating" ||
        status === "pending" ||
        status === "processing"
      )
        return "text-warning";
      if (status === "failed") return "text-danger";
    },
    //

    startInvestInterval() {
      this.investInterval = setInterval(() => {
        this.getInvests().then(res => {
          if (res) {
            if (
              this.invests[0].status === "failed" ||
              this.invests[0].status === "calculated"
            ) {
              clearInterval(this.investInterval);
            }
          }
        });
      }, 30000);
    }
  },
  async mounted() {
    // Start Loading
    this.$vs.loading();
    //
    await this.getDashboardData();
    await this.getInvests();

    // Close Loading
    this.$vs.loading.close();
    //
  },

  beforeDestroy() {
    clearInterval(this.investInterval);
  }
};
</script>

<style lang="scss">
@import "./payment.scss";
</style>
